import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPortalByOrgIdPortalIdAPI } from '../../api/getPortalByOrgIdPortalIdAPI';
import { checkIsPortalBooked, getFeatureFlagObj } from '../../utils';
import { portalModeAPI } from '../../api/portalModeAPI';
import { PORTAL_BOOKED } from '../../utils/constants';
import { updateRatingAPI } from '../../api/updateRatingAPI';

const initialState = {
    loading: false,
    error: false,
    errorObj: {},
    selectedPortalId: '',
    portal: {},
    updatedPortal: {},
    featureFlags: {},
    ratingScreen: {},
    portalRaw: {},
    authenticationStatus: false,
    // hoursLeft: 24
};

export const getPortalByOrgIdPortalId = createAsyncThunk(
    'portal/getPortalByOrgIdPortalId',
    async (orgPortal, { rejectWithValue }) => {
        try {
            return await getPortalByOrgIdPortalIdAPI(orgPortal);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updatePortalMode = createAsyncThunk('portal/portalModeAPI', async (obj) => {
    return await portalModeAPI(obj);
});

export const updateRating = createAsyncThunk('analytics/linkUpdate', async (obj) => {
    return await updateRatingAPI(obj);
});

const portalSlice = createSlice({
    name: 'portal',
    initialState,
    reducers: {
        setPortal: (state, action) => {
            state.portal = action.payload;
            state.authenticationStatus = action.payload?.authenticationStatus || false;
        },
        setResetPortal: (state, action) => {
            state.portal = action.payload;
        },
        setShowRatingScreen: (state, action) => {
            state.ratingScreen = action.payload;
        },
        clearShowRatingScreen: (state, action) => {
            state.ratingScreen = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPortalByOrgIdPortalId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
                state.portal = {};
            })
            .addCase(getPortalByOrgIdPortalId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action?.payload?.data) {
                    const data = {
                        ...action?.payload?.data,
                        connectionStatus: checkIsPortalBooked(action?.payload?.data)
                            ? 'online'
                            : action?.payload?.data?.connectionStatus,
                        status: {
                            ...action?.payload?.data?.status,
                            status:
                                action?.payload?.data?.status?.status === PORTAL_BOOKED
                                    ? 'online'
                                    : action?.payload?.data?.status?.status,
                        },
                    };
                    state.authenticationStatus = data.authenticationStatus;

                    // state.portal = data;

                    state.portalRaw = data;

                    state.featureFlags = getFeatureFlagObj(action.payload.data);
                } else {
                    state.portal = {};
                    state.error = true;
                }
            })
            .addCase(getPortalByOrgIdPortalId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(updatePortalMode.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updatePortalMode.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.portal = {
                    ...state.portal,
                    isPortalAwake: action.payload?.data?.isPortalAwake,
                };
            })
            .addCase(updatePortalMode.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            });
    },
});

export const { setPortal, setResetPortal, setShowRatingScreen, clearShowRatingScreen /*, setHoursLeft*/ } =
    portalSlice.actions;
export default portalSlice.reducer;
