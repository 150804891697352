import { useEffect } from 'react';
import { setMuteStatus, setParticipantData } from '../redux-toolkit/reducer/teamsReducer';
import { useDispatch, useSelector } from 'react-redux';

export default function useCmdTeamParticipantsHook({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.localPortal?.portal);

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};

            if (command === 'team-call-participant' && message?.name === portal.portal_id) {
                dispatch(
                    setParticipantData({
                        teamsParticipantData: commandData?.teamsParticipantData,
                        portalParticipantData: commandData?.portalParticipantData,
                    }),
                );
                dispatch(setMuteStatus(commandData?.isMuted));
            }

            if (command === 'team-call-mic-status' && message?.name === portal.portal_id) {
                dispatch(setMuteStatus(commandData?.isMuted));
            }
        };

        handleCommand();
    }, [message, dispatch, portal.portal_id]);
}
