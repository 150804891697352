import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createMsTeamsRejoinAPI } from '../../api/createMsTeamsRejoinAPI';
import { getMsTeamsRejoinAPI } from '../../api/getMsTeamsRejoinAPI';

const initialState = {
    isCallInitiated: false,
    isConnectionError: false,
    error: '',
    isCallEnded: false,
    teamsParticipantData: [],
    portalParticipantData: [],
    isMuted: false,
    waitingScreen: false,
    msTeamsData: null,

    portalMsTeamsRejoinData: {},
    portalMsTeamsRejoinDataLoading: false,
    portalMsTeamsRejoinDataError: false,
    portalMsTeamsRejoinDataErrorObj: {},

    portalMsTeamsRejoin5minData: {},
    portalMsTeamsRejoin5minDataLoading: false,
    portalMsTeamsRejoin5minDataError: false,
    portalMsTeamsRejoin5minDataErrorObj: {},
};
export const createMsTeamsRejoinData = createAsyncThunk(
    'portal/createMsTeamsRejoinData',
    async (msTeamsData, { rejectWithValue }) => {
        try {
            return await createMsTeamsRejoinAPI(msTeamsData);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const getMsTeamsRejoinData = createAsyncThunk(
    'portal/getMsTeamsRejoinData',
    async (msTeamsData, { rejectWithValue }) => {
        try {
            return await getMsTeamsRejoinAPI(msTeamsData);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setCallInitiated: (state, action) => {
            state.isCallInitiated = action.payload;
        },
        setConnectionError: (state, action) => {
            state.isConnectionError = action.payload;
        },
        setCallEnded: (state, action) => {
            state.isCallEnded = action.payload;
            state.waitingScreen = false;
        },
        setWaitingScreen: (state, action) => {
            state.waitingScreen = action.payload;
        },
        resetTeamState: () => initialState,
        setParticipantData: (state, action) => {
            state.teamsParticipantData = action.payload.teamsParticipantData;
            state.portalParticipantData = action.payload.portalParticipantData;
        },
        resetParticipantData: (state) => {
            state.teamsParticipantData = [];
            state.portalParticipantData = [];
        },
        setMuteStatus: (state, action) => {
            state.isMuted = action.payload;
        },
        setMsTeamsData: (state, action) => {
            state.msTeamsData = action.payload;
        },
        resetMsTeamsRejoin5minData: (state, action) => {
            state.portalMsTeamsRejoin5minData = {};
            state.portalMsTeamsRejoin5minDataLoading = false;
            state.portalMsTeamsRejoin5minDataError = false;
            state.portalMsTeamsRejoin5minDataErrorObj = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMsTeamsRejoinData.pending, (state) => {
                state.portalMsTeamsRejoinDataLoading = true;
                state.portalMsTeamsRejoinDataError = false;
            })
            .addCase(createMsTeamsRejoinData.fulfilled, (state, action) => {
                state.portalMsTeamsRejoinDataLoading = false;
                state.portalMsTeamsRejoinDataError = false;
                state.portalMsTeamsRejoinData = action.payload?.data;
            })
            .addCase(createMsTeamsRejoinData.rejected, (state, action) => {
                state.portalMsTeamsRejoinDataLoading = false;
                state.portalMsTeamsRejoinDataError = true;
                state.portalMsTeamsRejoinDataErrorObj = action.error;
            })
            .addCase(getMsTeamsRejoinData.pending, (state) => {
                state.portalMsTeamsRejoin5minDataLoading = true;
                state.portalMsTeamsRejoin5minDataError = false;
            })
            .addCase(getMsTeamsRejoinData.fulfilled, (state, action) => {
                state.portalMsTeamsRejoin5minDataLoading = false;
                state.portalMsTeamsRejoin5minDataError = false;
                state.portalMsTeamsRejoin5minData = action.payload.data;
            })
            .addCase(getMsTeamsRejoinData.rejected, (state, action) => {
                state.portalMsTeamsRejoin5minDataLoading = false;
                state.portalMsTeamsRejoin5minDataError = true;
                state.portalMsTeamsRejoin5minDataErrorObj = action.error;
            });
    },
});

export const {
    setCallInitiated,
    setCallEnded,
    setConnectionError,
    resetTeamState,
    setParticipantData,
    resetParticipantData,
    setMuteStatus,
    setWaitingScreen,
    setMsTeamsData,
    resetMsTeamsRejoin5minData,
} = teamsSlice.actions;

export default teamsSlice.reducer;
